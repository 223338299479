import React from "react";

interface LogoProps {
    size?: number;
    color?: string;
}

const RefreshSVG: React.FC<LogoProps> = ({size = 48, color = '#64C37D' }) => {
    return (
        <svg style={{width: `${size}px`, height: `${size}px`}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 512.004 512.004">
<g>
	<path
        style={{fill: color}}
        d="M215.647,176.775c4.538,0,9.002-1.763,12.354-5.085l71.158-70.526
		c0.047-0.047,0.088-0.099,0.133-0.147c0.105-0.105,0.199-0.22,0.302-0.328c0.285-0.306,0.571-0.613,0.834-0.938
		c0.094-0.117,0.177-0.241,0.268-0.36c0.256-0.332,0.51-0.664,0.739-1.013c0.082-0.123,0.152-0.255,0.23-0.38
		c0.225-0.361,0.448-0.722,0.647-1.097c0.06-0.115,0.11-0.236,0.167-0.353c0.202-0.404,0.397-0.807,0.566-1.225
		c0.037-0.091,0.065-0.186,0.102-0.278c0.177-0.457,0.342-0.917,0.48-1.388c0.021-0.074,0.036-0.15,0.057-0.222
		c0.139-0.497,0.261-0.999,0.357-1.507c0.004-0.021,0.011-0.039,0.014-0.06c0.014-0.074,0.016-0.146,0.029-0.22
		c0.082-0.482,0.149-0.966,0.191-1.455c0.013-0.151,0.015-0.299,0.025-0.449c0.02-0.321,0.051-0.641,0.053-0.964
		c0-0.103-0.013-0.205-0.014-0.308c-0.002-0.197-0.019-0.39-0.026-0.585c-0.018-0.378-0.037-0.753-0.078-1.129
		c-0.026-0.214-0.062-0.423-0.092-0.635c-0.053-0.354-0.106-0.708-0.181-1.057c-0.043-0.209-0.101-0.414-0.152-0.619
		c-0.088-0.35-0.177-0.698-0.285-1.042c-0.06-0.187-0.13-0.37-0.195-0.556c-0.125-0.354-0.254-0.706-0.402-1.052
		c-0.069-0.161-0.149-0.317-0.222-0.475c-0.166-0.358-0.336-0.714-0.525-1.06c-0.077-0.139-0.165-0.274-0.245-0.412
		c-0.206-0.351-0.413-0.697-0.642-1.034c-0.091-0.133-0.193-0.261-0.288-0.392c-0.234-0.323-0.47-0.646-0.726-0.952
		c-0.123-0.147-0.257-0.284-0.384-0.427c-0.208-0.233-0.405-0.474-0.625-0.696L228.745,5.195
		c-4.996-5.041-12.538-6.574-19.104-3.889c-6.568,2.687-10.871,9.065-10.903,16.162l-0.268,59.974
		c-39.197,12.645-74.001,38.065-97.996,73.628c-27.256,40.4-37.665,89.085-29.309,137.085
		c8.333,47.861,34.419,90.058,73.452,118.816c3.134,2.308,6.78,3.42,10.394,3.42c5.384,0,10.699-2.47,14.139-7.14
		c5.748-7.801,4.083-18.785-3.718-24.533c-66.003-48.627-81.759-140.002-35.868-208.023c17.245-25.562,41.362-44.676,68.74-55.873
		l-0.199,44.327c-0.032,7.097,4.216,13.514,10.759,16.259C211.057,176.329,213.36,176.775,215.647,176.775z"/>
    <path
        style={{fill: color}}
        d="M367.4,105.037c-7.8-5.748-18.784-4.085-24.533,3.716s-4.085,18.785,3.716,24.533
		c65.992,48.633,81.741,140.002,35.853,208.015c-17.245,25.56-41.354,44.68-68.726,55.878l0.199-44.328
		c0.032-7.095-4.216-13.514-10.758-16.258c-6.546-2.744-14.098-1.278-19.138,3.717l-71.163,70.522
		c-0.048,0.047-0.088,0.101-0.135,0.147c-0.113,0.115-0.215,0.239-0.325,0.357c-0.275,0.296-0.552,0.591-0.806,0.903
		c-0.119,0.145-0.223,0.302-0.337,0.451c-0.228,0.302-0.46,0.6-0.667,0.914c-0.117,0.175-0.218,0.364-0.329,0.544
		c-0.186,0.306-0.378,0.609-0.545,0.925c-0.098,0.184-0.179,0.377-0.271,0.565c-0.161,0.331-0.324,0.662-0.463,1.002
		c-0.069,0.167-0.122,0.342-0.186,0.51c-0.142,0.38-0.281,0.759-0.398,1.149c-0.043,0.149-0.075,0.303-0.117,0.454
		c-0.112,0.419-0.218,0.841-0.299,1.269c-0.007,0.036-0.019,0.07-0.025,0.108c-0.026,0.134-0.032,0.268-0.053,0.404
		c-0.065,0.404-0.124,0.806-0.161,1.215c-0.021,0.23-0.025,0.46-0.036,0.689c-0.013,0.26-0.041,0.516-0.042,0.777
		c0,0.083,0.011,0.165,0.012,0.248c0.002,0.28,0.022,0.557,0.036,0.834c0.019,0.292,0.03,0.585,0.062,0.875
		c0.032,0.299,0.082,0.594,0.13,0.89c0.042,0.269,0.081,0.537,0.137,0.802c0.061,0.296,0.139,0.586,0.215,0.877
		c0.07,0.262,0.135,0.524,0.215,0.783c0.087,0.276,0.189,0.546,0.289,0.818c0.097,0.266,0.192,0.531,0.303,0.792
		c0.106,0.251,0.227,0.494,0.345,0.739c0.129,0.269,0.255,0.539,0.398,0.801c0.125,0.23,0.266,0.453,0.4,0.676
		c0.158,0.262,0.311,0.523,0.482,0.775c0.153,0.226,0.321,0.441,0.484,0.66c0.173,0.233,0.343,0.468,0.528,0.694
		c0.201,0.243,0.418,0.471,0.633,0.704c0.142,0.153,0.269,0.317,0.416,0.466l70.53,71.16c3.361,3.39,7.872,5.195,12.464,5.195
		c2.235,0,4.489-0.427,6.639-1.307c6.568-2.687,10.871-9.065,10.903-16.161l0.268-59.967c39.193-12.646,73.991-38.079,97.983-73.639
		c27.256-40.397,37.666-89.078,29.312-137.074C432.508,175.994,406.427,133.797,367.4,105.037z"/>
</g>
</svg>
    );
}

export default RefreshSVG;
