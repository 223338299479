export function generateRandomBytes(length) {
    const bytes = [];
    for (let i = 0; i < length; i++) {
        bytes.push(Math.floor(Math.random() * 256));
    }
    return bytes;
}

export function generateObjectId() {
    const timestamp = Math.floor(Date.now() / 1000); // Get current timestamp in seconds
    const processId = generateRandomBytes(5); // Generate 5 random bytes
    const counter = generateRandomBytes(3); // Generate 3 random bytes

    // Concatenate the components in the correct order
    const objectId = timestamp.toString(16).padStart(8, '0') +
        processId.map(byte => byte.toString(16).padStart(2, '0')).join('') +
        counter.map(byte => byte.toString(16).padStart(2, '0')).join('');

    return objectId;
}

export function darkenColour(hex, amount): string {
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);

    // Use higher amount to darken more (0-255)
    const darkerR = Math.max(r - amount, 0).toString(16).padStart(2, '0');
    const darkerG = Math.max(g - amount, 0).toString(16).padStart(2, '0');
    const darkerB = Math.max(b - amount, 0).toString(16).padStart(2, '0');

    return `#${darkerR}${darkerG}${darkerB}`;
}

export function adjustColor(hex, amount) {
    // Convert hex to RGB first
    let r = parseInt(hex.substring(1, 3), 16);
    let g = parseInt(hex.substring(3, 5), 16);
    let b = parseInt(hex.substring(5, 7), 16);

    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if(max === min) {
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    // Adjust lightness
    l = Math.max(0, Math.min(1, l + amount)); // Ensure l is between 0 and 1

    // Convert HSL back to RGB


    const rgb = hslToRgb(h, s, l);

    // Convert RGB back to Hex
    function componentToHex(c) {
        const hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }

    return "#" + componentToHex(rgb[0]) + componentToHex(rgb[1]) + componentToHex(rgb[2]);
}

function hslToRgb(h, s, l){
    let r, g, b;

    if(s === 0){
        r = g = b = l; // achromatic
    } else {
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1/3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1/3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

function hue2rgb(p, q, t){
    if(t < 0) t += 1;
    if(t > 1) t -= 1;
    if(t < 1/6) return p + (q - p) * 6 * t;
    if(t < 1/2) return q;
    if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
    return p;
}


export function adjustAlpha(hex, alpha): string {
    const color = hex.replace('#', '');
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    const o = alpha;
    const darker = `rgba(${r}, ${g}, ${b}, ${o})`;
    return darker;
}

export function getQueryParams(url){
    const urlObject = new URL("https://chat.orbichat.local/?id=andy&origin=https://orbichat.local");
    const params = new URLSearchParams(url.search);
}

export function getSearchParams(url){
    const urlObject = new URL(url);
    return new URLSearchParams(url.search);
}