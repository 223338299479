import React from "react";

interface LogoProps {
    size?: number;
    color?: string;
}

const SendSVG: React.FC<LogoProps> = ({size = 48, color = '#64C37D' }) => {
    return (
        <svg style={{width: `${size}px`, height: `${size}px`}} version="1.1" id="Layer_1"
             xmlns="http://www.w3.org/2000/svg">
            <clipPath id="a">
                <path
                    d="M14.84.054a.864.864 0 011.107 1.107l-5.189 14.27a.864.864 0 01-1.423.316L6.15 12.56a.864.864 0 01-.056-1.16l7.03-8.522L4.6 9.908a.864.864 0 01-1.16-.056L.252 6.666A.864.864 0 01.57 5.243z"/>
            </clipPath>
            <g style={{fill: color}}
                clipPath="url(#a)" transform="rotate(45 6.516 4.341)">
                <path d="M0 0h16v16H0z"/>
            </g>
        </svg>
    );
}

export default SendSVG;
