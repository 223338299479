import React, {useEffect, useRef, useState} from "react";
// @ts-ignore
import {adjustColor, darkenColor, generateObjectId, getSearchParams} from "./Utils";
import config from "./config";
import RefreshSVG from "./components/Refresh.svg";
import SendSVG from "./components/Send.svg";
import Markdown from "react-markdown";

const App = () => {


    // TODO: Check that the origin is an allowed origin. If not, close the iframe.







    const url = new URL(window.location.href);

    console.log('url',url);

    const path = url.pathname.substring(1); // Exclude the leading slash
    const orgId = getSearchParams(url).get('orgId');
    console.log('orgId', orgId);
    const origin = getSearchParams(url).get('origin');
    console.log('origin', origin);
    console.log(path);

    const isPreviewOrigin = ['https://app.panhuman.local', 'https://app.panhuman.com'].includes(origin);
    const [chatId, setChatId] = useState(null);

    let initializing = false;
    const [messages, setMessages] = useState([]);
    const [settings, setSettings] = useState(
        {
            color: '#6887A1',
            gradient: 0,
            welcome: 'Welcome! How can we help you today?',
            avatarUrl: undefined,
            botName: 'OrbiChat',
            chatId:'655ef6f039764af2c51df831',
            companyName: "OrbiChat",
            showPopup: true,
            iconId: "chat_1",
            _id: undefined
        });
    const [minified, setMinified] = useState(false);
    const [currentMessage, setCurrentMessage] = useState({text: '', role: 'agent', time: 0});
    const [isTyping, setIsTyping] = useState(false);
    const lastMessageRef = useRef(null);
    const isMounted = useRef(true);
    const [backgroundValue, setBackgroundValue] = useState('');
    const [availableQuota, setAvailableQuota] = useState(true);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [escalateToEmail, setEscalateToEmail] = useState(false);
    const [directToEmail, setDirectToEmail] = useState(false);
    const [hasExistingMessages, setHasExistingMessages] = useState(false);
    const escalateMessageRef = useRef(null);
    const [functionCallArgs, setFunctionCallArgs] = useState(null);
    const [promptMode, setPromptMode] = useState('general');

    useEffect(() => {
        if (isPreviewOrigin) {
            setChatId(settings.chatId);
        } else {
            setChatId(localStorage.getItem("chatId") || generateObjectId());
        }
    }, [settings.chatId, isPreviewOrigin]);

    const handleFormChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'message':
                setMessage(value);
                break;
            default:
                break;
        }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        let submissionReason;
        if (escalateToEmail) {
            submissionReason = 'escalateToEmail';
        } else if (!availableQuota) {
            submissionReason = 'noAvailableQuota';
        }

        const formData = {
            name: name,
            email: email,
            message: message,
            orgId: orgId,
            chatId: chatId,
            submissionReason: submissionReason
        };

        try {
            const response = await fetch(`${config.API_URL}/leads/offline-message`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
                integrtring: undefined
            });

            if (!response.ok) {
                throw new Error('Failed to send message');
            }

            const responseData = await response.json();
            console.log('Message sent:', responseData);
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    useEffect(() => {
        // Listen for chat data from parent window
        window.addEventListener('message', async function(event) {
            console.log('iframe received message: ', event);
            const messageType = event.data.type;

            switch(messageType) {
                case 'REFRESH_SETTINGS': {
                    // Call your API to fetch new settings
                    try {
                        const orgId = getSearchParams(url).get('orgId'); // Make sure you have the correct orgId
                        const response = await fetch(`${config.API_URL}/settings/${orgId}`);
                        if (!response.ok) {
                            throw new Error('Failed to fetch settings');
                        }
                        const settingsData = await response.json();

                        // Update state with the new settings
                        setSettings(settingsData);
                        console.log('Settings updated:', settingsData);
                    } catch (error) {
                        console.error('Error fetching settings:', error);
                    }
                }
                case 'DIRECT_TO_EMAIL': {
                    console.log('DIRECT_TO_EMAIL messages', messages)
                    console.log('DIRECT_TO_EMAIL hasExistingMessages', hasExistingMessages)
                    if (!hasExistingMessages) setMessages([]);
                    setDirectToEmail(true);
                    break;

                }
            }

        });
    }, [hasExistingMessages]);





// Generate a new ObjectId


    // This effect runs whenever the messages array changes
    useEffect(() => {

        console.log('init Data RAN use effect ran', messages);
        initData().then();

        // Cleanup function to set the flag when the component unmounts
        return () => {
            isMounted.current = false;
            // messagesSource.close();
        };
    }, []);

    useEffect(() => {
        if (settings.color && typeof settings.gradient !== 'undefined') {
            let amount;
            switch (settings.gradient) {
                case -1:
                    amount = -0.1;
                    break;
                case 0:
                    amount = 0;
                    break;
                case 1:
                    amount = 0.2;
                    break;
                default:
                    amount = 0; // Default case to handle unexpected values
            }
            const adjustedColor = adjustColor(settings.color, amount);
            console.log('amount adjustColor', amount, adjustedColor);
            const newBackgroundValue = `linear-gradient(135deg, ${settings.color}, ${adjustedColor})`;
            setBackgroundValue(newBackgroundValue);
        }
    }, [settings.color, settings.gradient]);


    const chat = async (e, message) => {
        e.preventDefault();

        if (!message) return;

        e.preventDefault();

        if (!message) return;
        setIsTyping(true);
    };



    async function initData() {
        try {
            let chatId;
            let settingsData;
            let messagesData:any = {}; // Initialize messagesData to be an empty array by default
            if (isPreviewOrigin) {
                const settingsResponse = await fetch(`${config.API_URL}/settings/${orgId}`);
                settingsData = await settingsResponse.json();
                chatId = settingsData.chatId;
                setChatId(chatId); // Update the state

                if (!initializing) {
                    const messagesResponse = await fetch(`${config.API_URL}/chat/${chatId}`);
                    messagesData = await messagesResponse.json();
                    console.log('messagesData 1', messagesData)
                }
            } else {
                chatId = localStorage.getItem("chatId") || generateObjectId();
                setChatId(chatId); // Update the state
                const settingsFetch = fetch(`${config.API_URL}/settings/${orgId}`);
                let fetchPromises = [settingsFetch];
                if (!initializing) {
                    const messagesFetch = fetch(`${config.API_URL}/chat/${chatId}`);
                    fetchPromises.push(messagesFetch);
                }
                const responses = await Promise.all(fetchPromises);
                const settingsResponse = responses[0];
                settingsData = await settingsResponse.json();

                if (!initializing && responses.length > 1) {
                    const messagesResponse = responses[1];
                    if (messagesResponse.ok) {
                        messagesData = await messagesResponse.json();
                    }
                }
            }

            setSettings(settingsData); // Set settings data

            const welcomeMessage = { role: 'agent', text: settingsData.welcome };
            let initialMessages = [welcomeMessage];

            // If there are fetched messages, prepend the welcome message
            if (messagesData && messagesData.messages.length > 0) {
                setHasExistingMessages(true); // Assuming there's a need to track if existing messages are present
                initialMessages = [welcomeMessage, ...messagesData.messages];
            } else {
                setHasExistingMessages(false);
            }

            console.log('initialMessages', initialMessages);

            if (isMounted.current) {
                setMessages(initialMessages);
            }

            console.log('messages', messagesData)

        } catch (error) {
            console.error(error);
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            handleMessageSubmit(event);
        }
    };

    const handleMessageSubmit = async (event) => {
        event.preventDefault();
        if (currentMessage.text.trim() === '') {
            return;
        }
        currentMessage.time = Date.now();
        currentMessage.role = 'user';

        if (currentMessage.text.trim() !== '') {
            setMessages(existingMessages => [...existingMessages, currentMessage]);
            setCurrentMessage({text: '', role: 'agent', time: 0});
        }
        const agentResponse = await getAgentResponse(chatId, currentMessage);
        console.log('agentResponse', agentResponse);
        // setMessages(existingMessages => [...existingMessages, agentResponse]);
    };

   function handleSearch (){

       const userMessage = {
           text: "What kind of information are you looking for?",
           role: 'assistant',
           time: Date.now(),
       };
       setMessages(existingMessages => [...existingMessages, userMessage]);

   }

    async function getAgentResponse(chatId, message) {

        // const messagesSource = new EventSource('https://api.panhuman.local/messages');
        /* Note that without changing the nginx config, these may be sent as a single request
        * needed to turn off buffering */
        const response = await fetch(`${config.API_URL}/chat/${chatId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({orgId, message, preview: isPreviewOrigin, promptMode}),
            integrtring: undefined
        });

        if (response.ok) {
            const reader = response.body.getReader();
            let newMessage = null;

            const processStream = async () => {
                while (true) {
                    // .read() returns 2 properties
                    const {done, value} = await reader.read()
                    let chunk = new TextDecoder('utf-8').decode(value)

                    console.log('chunk', JSON.stringify(chunk));
                    console.log('chunk.length', chunk.length);
                    // if done is true
                    if (done) {
                        console.log('stream completed')

                        // setGenerating(false)
                        break;
                    }

                    let chunks = chunk.split("£$\n\n");

                    for (let i = 0; i < chunks.length; i++) {
                        console.log('chunk', chunks[i]);
                        chunks[i] = chunks[i].replace(/data:/, '');
                        if (chunks[i].length === 0) continue;
                        console.log(`Message ${i + 1}`, JSON.parse(chunks[i]));

                        const messageObject = JSON.parse(chunks[i]);

                        if (messageObject.type === 'function') {
                            console.log('functionCall', chunks[i]);
                            functionCaller({
                                functionName: messageObject.functionName,
                                functionArgs: messageObject.functionArgs
                            });
                        } else {
                            setPromptMode(messageObject.mode);
                            console.log('chunk.message', messageObject.message);
                            if (!newMessage) {
                                newMessage = {
                                    text: messageObject.message,
                                    role: 'assistant',
                                    time: Date.now(),
                                };
                                setMessages(existingMessages => [...existingMessages, newMessage]);
                            } else {
                                newMessage.text += messageObject.message;
                                setMessages(existingMessages => existingMessages.map(msg => msg === newMessage ? newMessage : msg));
                            }
                        }
                    }



                    // parse the chunk
                    // const parsed = JSON.parse(chunk)



                    console.log('parsed chunk', chunk);

                    // append to the response

                }
            }

            return processStream().catch(err => console.log('--stream error--', err))
        }
    }

    useEffect(() => {
        if (escalateToEmail && escalateMessageRef.current) {
            escalateMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, escalateToEmail]);

    const handleInputChange = (event) => {
        setCurrentMessage({text: event.target.value, role: 'agent', time: 0});
        if (escalateToEmail) {
            setEscalateToEmail(false);
        }

        if (directToEmail) {
            setDirectToEmail(false);
        }

        const target = event.target;
        target.style.height = 'auto';
        target.style.height = `${target.scrollHeight}px`;
    };

    const minify = () => {
        const type = minified ? 'maximize' : 'minify';
        const message = {type};
        window.parent.postMessage(message, '*');
        setMinified(!minified);
    }

    const handleRefresh = async () => {
        if (isPreviewOrigin){
            await fetch(`${config.API_URL}/chat/refresh/${chatId}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({orgId}),
                    integrtring: undefined
                });
        } else {
            const newChatId = generateObjectId();
            localStorage.setItem("chatId", newChatId);
            setChatId(newChatId);
        }

        console.log('settings', settings);

        const welcomeMessage = {
            role: 'agent',
            text: settings.welcome,
        };
        setMessages([welcomeMessage]);
    }

    const handleClose = () => {
        console.log('closeChat')
        // Send a message to the parent window to close the iframe
        window.parent.postMessage({ type: "CLOSE_CHAT" }, "*");
    };



    const functionCaller = (functionCallJSON: {functionName: string, functionArgs: any}) => {
        console.log('functionCaller', functionCallJSON)
        const type = functionCallJSON.functionName;
        const details = functionCallJSON.functionArgs;

        switch(type) {
            case 'send_email':
                console.log('send_email', details);
                setEscalateToEmail(true);
                break;
            case 'goto_page':
                setFunctionCallArgs({type, details});
                setMessages(existingMessages => [...existingMessages, {role: 'navigator', text: 'Are you sure you want to navigate to a new page?', time: Date.now()}]);
                break;
            case 'activate_use_case_suitability_chat':
                setPromptMode('useCaseSuitability');
                break;
        }

    }

    const processGotoPage = async (confirm: boolean) => {
        if (confirm) {
            const message = {
                type: functionCallArgs.type,
                details: functionCallArgs.details
            };
            window.alert(`processGotoPage ${JSON.stringify(message)}`);
            // window.parent.postMessage(message, '*');
        } else {
            const userMessage = {
                text: "Let's not navigate to the page, instead lets look at the answer here",
                role: 'user',
                time: Date.now(),
            };
            // Add the user message to the chat
            setMessages(existingMessages => [...existingMessages, userMessage]);
            // Process the user message as if the user had entered it
            await getAgentResponse(chatId, userMessage);
        }

        setFunctionCallArgs(null)
    }


    return (
        <div id="chat-container">
            <div id="chat-header" style={{ background: backgroundValue}}>
                <div className='header-avatar'>
                </div>
                <div className='header-greeting'>
                    <span>{settings.companyName}</span>
                </div>
                <div className="header-close">
                    <button className="cool-close-button"
                            onClick={handleClose}
                            style={{color: settings.color}}
                    >X</button>
                </div>
            </div>
            <div id="chat-body">
                {(availableQuota || !escalateToEmail) && (
                    messages.map((message, index) => {
                            if (message.role === 'user'){
                                return (
                                    <div className="chat-message-wrapper user-message" key={index} ref={index === messages.length - 1 ? lastMessageRef : null}>
                                        <div className="chat-message user-message">{message.text}</div>
                                    </div>
                                )
                            } else if (message.role === 'navigator') {
                                return (
                                    <div className="chat-message-wrapper agent-message" key={index} ref={index === messages.length - 1 ? lastMessageRef : null}>
                                        <div className="chat-message agent-message helper">
                                            <div>{message.text}</div>
                                            <div className="helper-btns-wrapper">
                                                <button onClick={() => processGotoPage(true)}>Yes</button>
                                                <button onClick={() => processGotoPage(false)}>No</button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div className="chat-message-wrapper  agent-message" key={index}
                                         ref={index === messages.length - 1 ? lastMessageRef : null}>
                                        {/*<div className="chat-message">{message.text}</div>*/}
                                        <Markdown className="chat-message">{message.text}</Markdown>
                                    </div>
                                );
                            }

                    })
                )}

                {(!availableQuota || escalateToEmail || directToEmail) && (
                    <div>
                        {directToEmail ? (
                                <div className="chat-message-wrapper  agent-message">
                                    <div className="chat-message">Welcome to the OrbiChat Agent.  If you let us know your
                                        email address and leave a message someone will respond within the next 24 hours.
                                        {availableQuota && <span>I might be able to help with any queries you have.</span>}
                                    </div>
                                </div>
                        ) : (
                            (!availableQuota || escalateToEmail) && (
                                !availableQuota ? (<div className="chat-message-wrapper  agent-message">
                                <div className="chat-message">Sorry, the chatbot is offline at the moment.</div>
                                </div>
                            ) :
                            (
                                <div className="chat-message-wrapper  agent-message" ref={escalateMessageRef}>
                                    <div className="chat-message">We are not able to access a live reprentative at this time.
                                        If you would like to let us know your email address and add any additional message.
                                        We will forward this chat to a rep.  They will respond within the next 24 hours.</div>
                                    </div>
                                )
                            )
                        )}

                        {/*{!availableQuota ? (<div className="chat-message-wrapper  agent-message">*/}
                        {/*    <div className="chat-message">Sorry, the chatbot is offline at the moment.</div>*/}
                        {/*</div>*/}
                        {/*) :*/}
                        {/*    (*/}
                        {/*    <div className="chat-message-wrapper  agent-message" ref={escalateMessageRef}>*/}
                        {/*        <div className="chat-message">We are not able to access a live reprentative at this time.*/}
                        {/*        If you would like to let us know your email address and add any additional message.*/}
                        {/*            We will forward this chat to a rep.  They will respond within the next 24 hours.</div>*/}
                        {/*    </div>*/}
                        {/*)}*/}

                        <div id="offline-form" className="offline-form">
                            <form className="offline-form-content" onSubmit={handleFormSubmit}>
                                <div className="form-group">
                                    <input type="text" name="name" className="form-control" placeholder="Name"
                                           value={name} onChange={handleFormChange}/>
                                </div>
                                <div className="form-group">
                                    <input type="email" name="email" required={true} className="form-control"
                                           placeholder="Email" value={email} onChange={handleFormChange}/>
                                </div>
                                <div className="form-group">
                                    <textarea name="message" className="form-control" placeholder="Your message"
                                              value={message} onChange={handleFormChange}/>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="submit-btn"
                                            style={{background: settings.color}}>Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                )}
            </div>
            <div id="chat-footer">
                <div id="chat-actions">
                    <button onClick={handleSearch}>Search</button>
                </div>
                <form>
                    <textarea
                        name="chat-message"
                        value={currentMessage.text}
                        placeholder="Type a message here and hit Enter..."
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        disabled={!availableQuota}
                    />
                    <button className="submit-btn" type="submit" onClick={handleMessageSubmit}
                            disabled={!currentMessage.text.trim() || !availableQuota}>
                        <SendSVG size={24}  color={settings.color}/>
                    </button>
                </form>
                <div className="footer-actions">
                    <div className="powered-by">
                        <a href="https://www.panhuman.com" target="_blank" rel="noopener noreferrer">
                            Powered by OrbiChat
                        </a>
                    </div>
                    <button className="icon-btn" onClick={handleRefresh}>
                        <RefreshSVG size={24} color={settings.color}/>
                    </button>
                </div>
            </div>


            {/*<div className={isTyping ? "" : "hide"}>*/}
            {/*    <p>*/}
            {/*        <i>{isTyping ? "Typing" : ""}</i>*/}
            {/*    </p>*/}
            {/*</div>*/}
        </div>
    );
};

export default App;
